import { Component, OnInit } from "@angular/core";
import { DashBoardComponent } from "../../pages/pages/dash-board/dash-board.component";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { SocioService } from "src/app/services/socio.service";
import util from "../../../util";

declare var $;

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  user: any;
  imagemperfil = util.storage + "perfil/Silhueta-png-cinzento-300x284.png";

  constructor(
    private router: Router,
    private userservice: UserService,
    private socioService: SocioService
  ) {}
  menu = [];
  teste() {
    console.log("teste");
  }
  ngOnInit() {
    const id = sessionStorage.getItem("usuario");
    if (!id) {
      this.router.navigate(["/"]);
    }
    this.userservice
      .getusuariosunico(id)
      .then((res) => {
        this.user = res.body;
        if (this.user.foto) {
          this.imagemperfil = util.storage + this.user.foto;
        }

        // console.log(res.body)
        if (res.body.grupo_id != 2 && res.body.grupo_id != 7) {
          this.socioService.verificarSocio(id).then((r) => {
            if (r.body) {
              this.menu = [
                { titulo: "Início", link: "/Main/Home", icone: "" },
                { titulo: "Perfil", link: "/Main/Perfil", icone: "" },
                { titulo: "Associação", link: "/Main/Associacao", icone: "" },
                { titulo: 'Inscrição', link: '/Main/inscricao', icone: '' },

                // {
                //   titulo: "Lista de associados",
                //   link: "/Main/Lista-socio",
                //   icone: "",
                // },
                {
                  titulo: "Acompanhar pagamento",
                  link: "/Main/Acompanhar",
                  icone: "",
                },
                { titulo: "Histórico", link: "/Main/Historico", icone: "" },
                { titulo: "Valores", link: "/Main/Valores", icone: "" },
            { titulo: 'Certificados', link: '/Main/Certificados', icone: '' },

            // { titulo: 'Minicursos', link: '/Main/Minicursos', icone: '' },
            { titulo: 'Votação', link: '/Main/Votacao', icone: '' },
// { titulo: 'Poster', link: '/Main/Poster', icone: '' },
                // { titulo: 'Categoria', link: '/Main/Categoria', icone: '' },
                // { titulo: 'Empenho', link: '/Main/empenho', icone: '' },
                // { titulo: 'Certificados', link: '/Main/Em-breve', icone: '' },
              ];
            } else {
              this.menu = [
                { titulo: "Início", link: "/Main/Home", icone: "" },
                { titulo: "Perfil", link: "/Main/Perfil", icone: "" },
                { titulo: "Associação", link: "/Main/Associacao", icone: "" },
                { titulo: 'Inscrição', link: '/Main/inscricao', icone: '' },

                {
                  titulo: "Acompanhar pagamento",
                  link: "/Main/Acompanhar",
                  icone: "",
                },
                { titulo: "Histórico", link: "/Main/Historico", icone: "" },
            { titulo: 'Certificados', link: '/Main/Certificados', icone: '' },

            // { titulo: 'Minicursos', link: '/Main/Minicursos', icone: '' },
// { titulo: 'Poster', link: '/Main/Poster', icone: '' },
                // { titulo: 'Valores', link: '/Main/Valores', icone: '' },

                // { titulo: 'Categoria', link: '/Main/Categoria', icone: '' },
                // { titulo: 'Convite para o jantar', link: '/Main/Em-breve', icone: '' },
                // { titulo: 'Certificados', link: '/Main/Em-breve', icone: '' },
              ];
            }
          });
        } else {
          this.menu = [
            { titulo: "Início", link: "/Main/Home", icone: "" },
            { titulo: "Perfil", link: "/Main/Perfil", icone: "" },
            { titulo: "Associação", link: "/Main/Associacao", icone: "" },
            { titulo: 'Administrar Eventos', link: '/Main/Admin-Eventos', icone: '' },

            { titulo: 'Eventos', link: '/Main/inscricao', icone: '' },
            {
              titulo: "Acompanhar pagamento",
              link: "/Main/Acompanhar",
              icone: "",
            },
            {
              titulo: "Lista de associados",
              link: "/Main/Lista-socio",
              icone: "",
            },
            { titulo: 'Inscritos nos Eventos', link: '/Main/Lista-inscrito', icone: '' },
            {
              titulo: "Lista de usuários",
              link: "/Main/Listar-usuario",
              icone: "",
            },
            { titulo: "Histórico", link: "/Main/Historico", icone: "" },
            { titulo: "Valores", link: "/Main/Valores", icone: "" },
            { titulo: "Patrocinio", link: "/Main/Patrocinio", icone: "" },

            

            // { titulo: 'Categoria', link: '/Main/Categoria', icone: '' },

            // { titulo: 'Empenho', link: '/Main/empenho', icone: '' },
            { titulo: 'Certificados', link: '/Main/Certificados', icone: '' },
            { titulo: 'Minicursos', link: '/Main/Minicursos', icone: '' },
            { titulo: 'Votação', link: '/Main/Votacao', icone: '' },
            // { titulo: 'Pôster', link: '/Main/Poster', icone: '' },
          ];
        }
      })
      .catch((err) => {
        console.log(err);
      });

    $(document).ready(() => {
      $(".sidebar-menu").tree();
    });
  }
  navigate(rota) {
    this.router.navigate([rota]);
  }
  logout() {
    sessionStorage.setItem("usuario", "");
    this.router.navigate(["/"]);
  }
}
